html {
  font-size: 14px;
}
body {
	font-family: 'Comfortaa';
  color: #e2ead3;
	background-color: #000000;
	margin: 0px;
//	overflow: hidden;
}
*, *:before, *:after {
	-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

a {
    color: black;
}
a:hover {
    color: #007BE9;
    text-decoration: underline;
}

#linkSilo{
 display: none;
 z-index: 5;
}

#polynavbar-hdr{
position: relative;
left: 1px;
top: 0px;
z-index:8;
}
#polynet-navbar-collapse-1 {
position: relative;
z-index: 6;
}
.lnkDiv {
  display: block;
  width: 80px;
  height: 1.6em;
  text-align:center;
  cursor: pointer;
  color: #c8c8c8;
  font-family: 'Roboto';
  font-size: 1em;  
  text-decoration: none;
  vertical-align: top;
  border-radius: 6px;
  border: solid 1px;
  //background-color: #666633;
  background-color: rgba(82, 17, 69, 0.8);
}

.lnkDiv a {
 color: #b9b69c;
 font-weight:normal;
 text-decoration: none;
}

.info {
	position: absolute;
  color: #738855;
	bottom: 0.5em;
	right: 1em;	
	padding: 0 1em;
	text-align:center;
}

.indent {
  text-indent: 25px;
}

.credits {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 0.7em;
    color: #777;
}

.newsfresh {
    display: block;
    margin-left:2em;
}
.newsfresh .newsrow{

    margin-bottom: 1px;
    border-radius: 4px;
    background-color: rgba(46, 70, 27, 0.5);
}

.newsfresh .row .olivegreen {
    background-color: darkolivegreen;  
}

.newsfresh .row .date {
    color: #0a0700; 
    padding-left: 2px;
    text-align: center;
    border-radius: 4px;
    min-height: 1px;
    min-width: 70px;    
    max-width: 78px;
}

.newsfresh .newsdata {
  color: #000;
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 400;
  text-indent: 25px;
  text-align: justify;
  //background-color: ivory;
  background-color: rgba(245, 245, 153, 0.65);
}

.newsfreshli {
  color: #91ab6a;
  background-color: #333;
  font-size:12px;
}
a.btn-newstitle  {
color: #56bb58;

}

.greet {
	position: relative;
 // background-color: #102010;
	margin-top: -1.6em;
//	margin-left: 0.5em;
	min-width: 290px;

	padding: 0 3%;
//	pointer-events:none;
}

.greet .op  {
color: #91ab6a;
font-size: 1.5em;
}
.greet .reg {
display: block;
color: #79dea8;
//padding-top: 1em;
font-size: 1.3em;
font-weight: 300;
}
.greet .poly {
display: block;
color: #79dea8;
font-size: 2em;
//text-align: right;
padding-left:10em;
}
.greet .ntc {
color: #766e54;
font-size: 1em;
}

@media (max-width: 768px){
.greet .ntc, .greet .reg {
    display: none;
}
}

.btn-default {
    color: #aaa;
    background-color: #101010; 
    border-color: #438ac7;
}


@media (min-width: 768px){
.navbar-right {
    float: right !important;
    margin-right: 5px; 
}}

#polytree {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.row {
    margin-right: 5px;
}
.dropdown-menu {
  background-color: floralwhite;
}
.navbar-brand {
    height: 26px;
 
}
.navbar-xs .navbar-inverse .navbar { min-height:28px; height: 28px; border-bottom: 1px solid #899639; }
.navbar-xs .navbar-inverse .navbar .navbar-brand{ padding: 0px 12px;font-size: 16px;line-height: 28px; }
.navbar-xs .navbar-inverse .navbar .navbar-nav > li > a {  padding-top: 0px; padding-bottom: 0px; line-height: 26px; }

.navbar-toggle {
padding: 5px 5px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
}

@media screen and (min-width:1200px) {
	.info {
		font-size:1.3em;
	}
}

@media screen and (max-width: 600px) {
	.info {
		font-size:0.6em;
		width:100%;
		text-align:center;
		right:0;
	}
.credits {
		right:0;
		text-align:center;
	}
}

.contact_title {
    font-size: 1.2em;
    font-weight: 500;
    font-family: "Comfortaa";
}

.contact_class {
	width: 100%;
	height: 108px;
	margin: 4px 0 0 0;
	font-size: 12px;
	padding: 20px;
}
.modest {
color: #21251c;
font-size: 0.8em;
}
.label-default {
    color: #c5f9b9;
    background-color: #516921;
    border-color: #58b95a;
}

.btn-default {
    color: #c5f9b9;
    background-color: #516921;
    border-color: #58b95a;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 82%;
    margin: 30px auto;
  }
} 
.modal-header{
  background: olivedrab;
  border-radius: 4px 4px 0px 0px;
}
.modal-footer{
  background-color: darkolivegreen ;
  border-radius: 0px 0px 4px 4px;
}  
.modal-body{
  font-family: "Open Sans";
  color: black;
  font-weight: 400;
  text-align: justify; 
}  

.vertical-text {
	transform: rotate(90deg);
	transform-origin: left top 0;
}
thead {
  background-color: darkolivegreen;
  color: #FDFDFD;
  text-align:center;
}

table.tariff-table {
//  display: block;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
//  font-size: 100%;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  border-collapse: separate;
  border-spacing: 1px 0px;
}  

.tariff-table tr.odd{
  background-color: rgb(241, 239, 179); 
}
.tariff-table tr.even{
  background-color: rgb(243, 243, 167); 
}

.tariff-table tr > th {
    background-color: darkolivegreen;
    color: white;
    font-weight: normal;
    padding: 10px 15px;
    text-align: center;
  }

.tariff-table tr > td{
    color: rgb(35, 35, 25);
    padding: 5px 10px;
    text-align: center;
}


.tariff-table tr > td.column-3 {
text-align: justify;
}

.tariff-table tr > td.column-7, .tariff-table tr > td.column-6, .tariff-table tr > td.column-5, .tariff-table tr > td.column-4   {
background-color: #86a550;
    color: white;
}
.tariff-table tr > td.column-1 {
background-color: #65754a;
    color: white;
}

.tariff-table tr > td.servicedesc {
  font-size: 0.8em;
  font-style: italic;
}
.tariff-table tr > td.emphasize {
//  font-size: 1em;
  font-weight: 600;
}
ol {
    counter-reset: item;
}
ol li {
    display: block;
    position: relative;
    text-align: justify;
    padding-right: 8px;
    line-height: 2em;
}
ol li:before {
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 10px; /* space between number and text */
}

ol > li { 
    font-size: 1.1em;
    font-weight: 600;
}

ol li ol li {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.7em;
}
ol li ol li ol li { 
    font-size: 0.9em;
    font-weight: 300;
    line-height: 1.5em;
}

#iforgotpass {
    display: inline;
    margin-left: 30px;
    text-decoration: underline;
    color: #c8c8c8;
}

#iforgotpass:hover {
    color: #233C8A; 
}

@media screen {
  #printSection {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSection, #printSection * {
    visibility:visible;
  }
  #printSection {
    position:absolute;
    left:0;
    top:0;
  }
}